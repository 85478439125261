<template>
  <el-card class="box-card"
           v-loading="loading">
    <div slot="header"
         class="clearfix">
      <span class="title">{{title}}</span>
    </div>
    <div class="content"
         v-html="content"></div>
  </el-card>
</template>

<script>
import api from "@/api/index";
export default {
  data () {
    return {
      loading: true,
      title: '',
      content: ''
    }
  },
  created () {
    this.$store.commit("layouts/setPathList", [{ name: '首页', path: '/home' }, { name: '平台公告' }]);
    this.$store.commit("layouts/changeBreadcrumb", true)
    this._initState(this.$route.params.id)
  },
  destroyed () {
    this.$store.commit("layouts/changeBreadcrumb", false)
  },
  methods: {
    async _initState (id) {
      const { status_code, message } = await api.announcementApi({ id })
      if (status_code === 200) {
        this.title = message.title
        this.content = message.content
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  font-size: 18px;
  color: #333333;
}
.content /deep/ p {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 14px;
  line-height: 20px;
}
</style>